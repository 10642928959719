import React from "react";
import {css, jsx} from "@emotion/core";

function getProperImageSize(node, aspectRatio) {
    if(!node) return [0,0];

    const rect = node.getBoundingClientRect();

    if( (rect.width / aspectRatio) > (rect.height * aspectRatio)) {
        return [ (rect.height * aspectRatio) + 'px', rect.height + 'px'];
    } else {
        return [ rect.width + 'px',  (rect.width / aspectRatio) + 'px'];
    }
}

const MarkupMedia = (props) => {
    const { markup, aspectRatio } = props;
    const imgContainer = React.useRef(null);
    const [height, setHeight] = React.useState('0px');
    const [width, setWidth] = React.useState('0px');

    const resizeImage = () => {
        const [width, height] =
            window.innerWidth > 1024
                ? getProperImageSize(imgContainer.current, aspectRatio)
                : [aspectRatio > 1 ? '80%' : '25%', aspectRatio > 1 ? 'auto' : '80%' ];
        setWidth(width);
        setHeight(height);
    };

    React.useLayoutEffect( () => {
        window.addEventListener('resize', resizeImage);
        resizeImage();
        return () => {
            window.removeEventListener('resize', resizeImage);
        };
    });

    const imageSizeStyle = css`
        img {
            width: ${width};
            height: ${height};
        }
    `;

    return <div css={imageSizeStyle} ref={imgContainer} dangerouslySetInnerHTML={{ __html: markup }}>
    </div>;
};

export default MarkupMedia;